import axios from "axios";
import * as bootstrap from "bootstrap";
import Swal from "sweetalert2";
import NProgress from "nprogress";

// before a request is made start the nprogress
axios.interceptors.request.use(config => {
  NProgress.start();
  return config;
});

// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
  NProgress.done();
  return response;
});

export default {
  install(Vue, options) {
    //Vue.use(axios)

    Vue.prototype.$bootstrap = bootstrap;
    Vue.prototype.$swal = Swal;

    // Функция обработки и перевода вайлов в base64
    Vue.prototype.$base64 = function(file) {
      return new Promise((resolve, reject) => {
        const fr = new FileReader();
        fr.onerror = reject;
        fr.onload = () => {
          resolve(fr.result);
        };
        fr.readAsDataURL(file);
      });
    };

    //Функция определяет обратобку запроса к серверу
    Vue.prototype.$ajax = async function(data, header = "") {
      let user = JSON.parse(localStorage.getItem("user"));
      if (user == null) {
        this.$router.push("/login");
        return;
      }
      if (Object.keys(user).length == 0) {
        this.$router.push("/login");
        return;
      }
      //  Подвязиваем данние пользователя из хранилища
      //infinitasapi.holderpos.com.ua

      

      

      data.pass = user.pass;
      data.login = user.login;
      data.console = "true";
      try {
        const response = await axios.post(
          "https://infinitasapi.holderpos.com.ua",
          data,
          // {
          //   headers: { "Content-Type": "application/x-www-form-urlencoded" },
          // }
        );
        console.log("%c" + data.action, "color:orange", response.data);
        // Тут будем обрабатывать ошибки
        return response.data;
      } catch (error) {
        this.$alertErr("Відсутній інтернет звʼязок");
        console.error(error);
      }
    };

    Vue.prototype.$console = (action = "", data) => {
      console.log("%c" + action, "color:orange", data);
    };

    Vue.prototype.$alertOK = text => {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: text,
        timerProgressBar: true,
        showConfirmButton: false,
        timer: 1500,
      });
    };

    Vue.prototype.$swal = text => {
      Swal.fire({
        title: text,
        showConfirmButton: false,
      });
    };

    Vue.prototype.$alertErr = (text, timer = 1500) => {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "error",
        title: text,
        timerProgressBar: true,
        showConfirmButton: false,
        timer: timer,
      });
    };

    Vue.prototype.$alertInfo = (text, timer = 1500) => {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "info",
        title: text,
        timerProgressBar: true,
        showConfirmButton: false,
        timer: timer,
      });
    };


    Vue.prototype.$modal = (title, html) => {
      Swal.fire({
        title: title,
        icon: "info",
        html: html,
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
      });
    };

    Vue.prototype.$modalinit = function(modalid) {
      let modal = new bootstrap.Modal(document.getElementById(modalid));
      return modal;
    };

    Vue.prototype.$timefull = function() {
      var time = new Date();
      var min = time.getMinutes();
      if (min < 10) min = "0" + min;
      var sec = time.getSeconds();
      if (sec < 10) sec = "0" + sec;
      return time.getHours() + ":" + min + ":" + sec;
    };

    Vue.prototype.$time = function() {
      var time = new Date();
      var min = time.getMinutes();
      if (min < 10) min = "0" + min;
      var sec = time.getSeconds();
      if (sec < 10) sec = "0" + sec;
      return time.getHours() + ":" + min;
    };

    Vue.prototype.$datemark = function() {
      return new Date().getTime();
    };
    Vue.prototype.$server = function() {
      return "https://infinitasapi.holderpos.com.ua";
    };
    Vue.prototype.$token = function() {
      return localStorage.getItem("token");
    };

    Vue.prototype.$date = function() {
      var date = new Date();
      var dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      var mm = date.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;
      var year = date.getFullYear();
      return year + "-" + mm + "-" + dd;
    };
    Vue.prototype.$getdate = function(timestamp) {
      var date = new Date(timestamp*1000);
      var dd = date.getDate();
      if (dd < 10) dd = "0" + dd;
      var mm = date.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;
      var year = date.getFullYear();
      return year + "-" + mm + "-" + dd;
    };

    Vue.prototype.$firstday = function() {
      var date = new Date();
      var mm = date.getMonth() + 1;
      if (mm < 10) mm = "0" + mm;
      var year = date.getFullYear();
      return year + "-" + mm + "-01";
    };

    Vue.prototype.$pass = function() {
      return localStorage.getItem("pass");
    };
    Vue.prototype.$user = function() {
      return localStorage.getItem("user");
    };

    Vue.prototype.$img = function() {
      //return localStorage.getItem('img')
      return "https://infinitasapi.holderpos.com.ua/images/";
    };
  },
};

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/main",
    name: "Main",
    meta: { layout: "Main" },
    component: () => import("@/views/Main.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
  },

  {
    path: "/",
    name: "Clients",
    component: () => import("@/views/Clients.vue"),
  },
  {
    path: "/contracts",
    name: "Contracts",
    component: () => import("@/views/Contracts.vue"),
  },
  {
    path: "/chenels",
    name: "Chenels",
    component: () => import("@/views/Chenels.vue"),
  },
  {
    path: "/messages",
    name: "Messages",
    component: () => import("@/views/Messages.vue"),
  },
  {
    path: "/events",
    name: "Events",
    component: () => import("@/views/Events.vue"),
  },
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/views/Settings.vue"),
  },
  {
    path: "/horeca",
    name: "Horeca",
    component: () => import("@/views/Horeca.vue"),
  },
  {
    path: "/reklama",
    name: "Reklama",
    component: () => import("@/views/Reklama.vue"),
  },
];

const router = new VueRouter({
  routes,
});

export default router;

import Vue from "vue";
import App from "./App.vue";
//import VueAwesomeSwiper from "vue-awesome-swiper";
//import "swiper/css/swiper.css";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
//import Vue2TouchEvents from "vue2-touch-events";
import VueResource from "vue-resource";
import NProgress from "nprogress";
import helper from "@/scripts/helper";
import * as bootstrap from "bootstrap";
import Swal from "sweetalert2";
import VueLodash from "vue-lodash";
import lodash from "lodash";



import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/js/bootstrap.min";
import "@/css/main.css";
import "@/css/adminlte.css";
import "nprogress/nprogress.css";
//import "@/css/style.css";

//require('bootstrap/dist/js/bootstrap.min.js')
//require('bootstrap/dist/css/bootstrap.min.css');
//require('bootstrap/dist/js/bootstrap.bundle.min')
Vue.use(bootstrap);
Vue.use(VueLodash, { name: "custom", lodash: lodash });
Vue.prototype.Swal = Swal;

//require('@fortawesome/fontawesome-free/js/all')
require("@fortawesome/fontawesome-free/css/all.css");

Vue.use(helper);
//Vue.use(VueAwesomeSwiper);
Vue.use(VueResource);
//Vue.use(Vue2TouchEvents);
Vue.use(DatePicker);
Vue.use(NProgress);
//Vue.use(createPopper)
// or with options
// Vue.use(VueLazyload, {
//   preLoad: 1.3,
//   error:
//     "https://protachka.com.ua/wp-content/themes/korona/image/no_image.png",
//   loading:
  
//     "https://razvozka.kiev.ua/wp-content/themes/roadster/images/car-dealer-loader.gif",
//   attempt: 1,
//   listenEvents: [
//     "wheel",
//     "mousewheel",
//     "resize",
//     "animationend",
//     "transitionend",
//     "touchmove",
//   ],
//   observer: true,
// });

//Vue.use(Swal)

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  http: {
    emulateJSON: true,
    emulateHTTP: true,
  },
  methods: {},
  render: h => h(App),
}).$mount("#app");
